import { request } from "@/request/http";

// 测试post 无参数
// export const testPost = p => post("/test/testApis", p);

export default {
  wxlogin_notice: (data) => {
    return request("POST", `/index.php/backlogin/wxlogin_notice`, data);
  },

  accountlogin: (data) => {
    return request("POST", `/index.php/backlogin/accountlogin`, data);
  },

  noticeLogin: (data) => {
    return request("POST", `/index.php/backlogin/noticeLogin`, data);
  },

  getLoginStatus: (data) => {
    return request("POST", `/index.php/backlogin/getLoginStatus`, data);
  },
};

