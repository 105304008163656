<template>
    <div class="login" id="container">
        <div class="logo">
            <img v-if="school_logo" :src="school_logo">
            <img v-else src="../assets/image/logo.jpg">  
        </div>
        <div class="swiperBox">
            <div class="swiper">
                <el-carousel :autoplay="false">
                    <el-carousel-item v-for="item in swiperList" :key="item.url">
                        <img :src="item.url" alt="">
                        <div class="swiperText">
                            <div class="textword">
                                {{item.title}}
                                <br>
                                {{item.titleText}}
                            </div>
                            <div class="textinfo">
                                {{item.info}}
                            </div>
                            <div class="textEnter">
                                <div class="inline-block appimg">
                                    <img :src="item.logoUrl" alt="">
                                </div>
                                <div class="inline-block appTest">{{item.logoText}}</div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="top" >
                <div class="welcome">                
                    <div v-show="loginType == 2">
                        <div class="navmenu">
                            <el-menu :default-active="active" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                                <el-menu-item index="1">扫码登录</el-menu-item>
                                <el-menu-item index="2">扫码注册</el-menu-item>
                            </el-menu>
                        </div>
                        <div class="wxQcodeBox">
<div  v-show="active == '1'" id="wx_login_container"></div>
                        </div>
                        
                        <div class="qrcodeBox" v-show="active == '2'"><img class="qrcode" src="../assets/image/xiaochengxuCode.jpg" alt=""></div>
                    </div>
                </div>
                <transition name="fade">
                    <div v-show="loginType == 1"  class="wxImg" @click="changeLoginType(2)"><img src="../assets/image/loginWX.png" alt=""></div>
                </transition>
                <transition name="fade">
                <div v-show="loginType == 2"  class="pcImg" @click="changeLoginType(1)"><img src="../assets/image/loginPC.png" alt=""></div>
                </transition>
                <div class="pclogin" v-show="loginType == 1">
                    <div class="navmenu">
                    <el-menu default-active="1" class="el-menu-demo" mode="horizontal">
                            <el-menu-item index="1">账号登录</el-menu-item>
                        </el-menu>
                    </div>
                    <div class="formItem">
                        <el-input
                            v-model="username"
                            placeholder="请输入手机号码"
                            @change="setInfoToCookie"
                            class="telInput"
                        ></el-input>
                    </div>
                    <div class="formItem">
                        <el-input
                            placeholder="请输入密码"
                            v-model="password"
                            @change="setInfoToCookie"
                            show-password
                            class="passwordInput"
                        ></el-input>
                    </div>
                    <div class="formItem">
                        <el-checkbox
                            v-model="rember"
                            @change="remberClick()"
                            :checked="rember">记住密码</el-checkbox>
                    </div>
                    <div class="formItem">
                        <el-button  class="loginButton" type="primary" @click="accountLogin()">登录</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            信息系统安全等级保护备案号：21021143171-20003 | 教育部APP备案号：教APP备2100053 | 网站备案号：辽ICP备17015248号-1
        </div>
        <Tabbar/>
        <!-- <div id="three">
        </div> -->
    </div>
</template>

<script>
// import * as THREE from "three";
// import { get, post } from "@/request/http";
import API from "@/api/login";
import Tabbar from "@/components/tabbar";
import {
    setCookie,
    getCookie,
    clearCookie,
    startLoading,
    endLoading,
    period
} from "@/utils/function";
import { Message } from "element-ui";
    var SEPARATION  =  100 
    var AMOUNTX  =  50 
    var AMOUNTY  =  50
    var container = ''
    var camera = ''
    var scene = ''
    var renderer = ''
    var particles = ''
    var particle = ''
    var count = 0
    var mouseX = 415
    var mouseY = -264.5
    var windowHalfX = window.innerWidth / 2
    var windowHalfY = window.innerHeight / 2
export default {
    name: "Home",
    components: {
        Tabbar
    },
    data() {
        return {
            // 学校logo图片
            school_logo:'',
            swiperList:[
                {
                    title:'Moolsnet',
                    titleText:'智慧教学管理平台',
                    info:'集成、易用、拓展、资源复用',
                    logoUrl:require("../assets/image/xiaologo.png"),
                    logoText:'获取小程序入口',
                    url:require("../assets/image/swiper1.jpg")
                },
                {
                    title:'MLabs',
                    titleText:'虚拟仿真实验 App',
                    info:'MLabs 为一款多终端 、多领域应用的虚拟仿真实验软件App ，目前涵盖包括化学、物理、生物、机械、土木、力学、医学等 30 余个专业领域，共计340 多个实验项目。',
                    logoUrl:require("../assets/image/applogo.png"),
                    logoText:'前往下载App',
                    url:require("../assets/image/swiper2.jpg")
                },
                {
                    title:'实验室安全教育',
                    titleText:'',
                    info:'基于实验室安全问题的复杂性，我司以化学、生物、机械实验室“应知应会”为标准，设计并制作以下关卡的安全知识通关游戏，趣味化展示实验室必会的安全知识。',
                    logoUrl:require("../assets/image/xiaologo.png"),
                    logoText:'获取小程序入口',
                    url:require("../assets/image/swiper3.jpg")
                },

            ],
            height:540,
            // 用户名
            username: "",
            // 密码
            password: "",
            // tab默认显示栏
            activeName: "first",
            // 记住密码多选框标识
            rember: true,
            // 是否展示等待验证中提示
            wait:false,
            // 切换登陆方式(1-微信扫码登录 2-输入账号密码登录)
            loginType: 2,
            active:'1'
        };
    },
    created() {
        const self = this;
        const wxJs = document.createElement('script')
        wxJs.type = 'text/javascript'
        wxJs.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
        document.body.appendChild(wxJs);
        // 从cookie中取值
        self.loadRember();
        self.judgeSchool();
    },
    mounted() {
        var self = this;
        let wx_redirect_uri =  process.env.VUE_APP_SYSTEM_URL+"/login.html";
        console.log(wx_redirect_uri)
        console.log(process.env.VUE_APP_ENV_MODE)
        self.$Loading.show()
        self.$Loading.hide()
        // 显示js微信登录二维码
        var obj = new WxLogin({
            self_redirect: true,
            id:"wx_login_container", // 显示二维码的容器的id
            appid: "wx83d6b672f9e825a0", // 此处是网站应用的appid
            scope: "snsapi_login", // 唯一值 snsapi_login
            // redirect_uri: encodeURI("https://www.mools.net/lims/api/laravel/public/index.php/backlogin/wxlogin_notice"),// 回调地址
            redirect_uri: encodeURI(wx_redirect_uri),// 回调地址(可以是前端，拿到微信code，再请求后端进行登录,必须是同一个域名下的链接，否则参数传递失败)
            // redirect_uri: encodeURI("https://pc.mools.net/login.html"),// 回调地址(可以是前端，拿到微信code，再请求后端进行登录,必须是同一个域名下的链接，否则参数传递失败)
            state: "",
            style: "black",
            href: "https://app.mools.net/lims/Manager/src/assets/css/wxQrcode.css", //https://某个域名下的css文件
        });//*/
        // 将方法挂载到window主链上
        // 从iframe中获取到回调函数中获取的微信返回的code
        window.jumpTop = function(code){
            var data = {
                code: code
            };
            API.wxlogin_notice(data)
                .then(result => {
                    if(result.code > 0)
                    {
                        // Message.success(result.msg);
                        if(result.type === 0)
                        {// 跳学生首页
                            self.$router.push("/student/reportList");
                        }
                        else if(result.type == 1 || result.type == 9)
                        {// 跳选择身份页
                            self.$router.push("/teacher/calendar");
                        }
                        else if(result.type == 7)
                        {
                            self.$router.push("/schoolAdmin/center");
                        }
                        else if(result.type == 6)
                        {
                            self.$router.push("/DY/index");
                        }
                    }
                })
                .catch(err => {});
        };
    },
    methods: 
    {
        // init() 
        // {
        //     let self = this
        //     container = document.getElementById('three')
        //     camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 100000 );
        //     camera.position.z = 3000;
        //     scene = new THREE.Scene();
        //     particles = new Array();
        //     var PI2 = Math.PI * 2;
        //     var material = new THREE.ParticleCanvasMaterial( {
        //         color: 0x0D5570,
        //         opacity : 0.2,
        //         program: function ( context ) {

        //             context.beginPath();
        //             context.arc( 0, 0, 1, 0, PI2, true );
        //             context.fill();
        //         }
        //     } );
        //     var i = 0;
        //     for ( var ix = 0; ix < AMOUNTX; ix ++ ) {
        //         for ( var iy = 0; iy < AMOUNTY; iy ++ ) {
        //             particle = particles[ i ++ ] = new THREE.Particle( material );
        //             particle.position.x = ix * SEPARATION - ( ( AMOUNTX * SEPARATION ) / 2 );
        //             particle.position.z = iy * SEPARATION - ( ( AMOUNTY * SEPARATION ) / 2 );
        //             scene.add( particle );
        //         }
        //     }
        //     renderer = new THREE.CanvasRenderer();
        //     renderer.setSize( window.innerWidth, window.innerHeight );
        //     container.appendChild( renderer.domElement );
        //     document.addEventListener( 'mousemove', self.onDocumentMouseMove, false );
        //     document.addEventListener( 'touchstart', self.onDocumentTouchStart, false );
        //     document.addEventListener( 'touchmove', self.onDocumentTouchMove, false );
	    //     window.addEventListener( 'resize', self.onWindowResize, false );
        // },
        // /**
        //  * @name:监听页面窗口变化，改变模型大小
        //  * @author: LANXINYU
        //  * @date:2022-04-14
        //  */
        // onWindowResize() 
        // {
        //     let self = this;
        //     windowHalfX = window.innerWidth / 2;
        //     windowHalfY = window.innerHeight / 2;
        //     camera.aspect = window.innerWidth / window.innerHeight;
        //     camera.updateProjectionMatrix();
        //     renderer.setSize( window.innerWidth, window.innerHeight );
        // },
        // /**
        //  * @name:监听鼠标移动事件,改变相机位置
        //  * @author: LANXINYU
        //  * @date: 2022-04-14
        //  */
        // onDocumentMouseMove( event ) 
        // {
        //     let self = this
        //     mouseX = event.clientX - windowHalfX;
        //     mouseY = event.clientY - windowHalfY;
        // },
        // /**
        //  * @name:监听鼠标点击事件,固定模型观察位置
        //  * @author: LANXINYU
        //  * @date: 2022-04-14
        //  */
        // onDocumentTouchStart( event ) 
        // {
        //     let self = this
        //     if ( event.touches.length === 1 ) {
        //         event.preventDefault();
        //         mouseX = event.touches[ 0 ].pageX - windowHalfX;
        //         mouseY = event.touches[ 0 ].pageY - windowHalfY;
        //     }
        // },
        // /**
        //  * @name:监听鼠标点击事件,可拖拽观察模型
        //  * @author: LANXINYU
        //  * @date: 2022-04-14
        //  */
        // onDocumentTouchMove( event ) 
        // {
        //     let self = this
        //     if ( event.touches.length === 1 ) {
        //         event.preventDefault();
        //         mouseX = event.touches[ 0 ].pageX - windowHalfX;
        //         mouseY = event.touches[ 0 ].pageY - windowHalfY;
        //     }
        // },
        // /**
        //  * @name:模型动画效果
        //  * @author: LANXINYU
        //  * @date: 2022-04-14
        //  */
        // animate() 
        // {
        //     requestAnimationFrame( this.animate );
        //     this.render();
        // },
        // /**
        //  * @name:渲染模型
        //  * @author: LANXINYU
        //  * @date: 2022-04-14
        //  */
        // render() 
        // {
        //     let self = this;
        //     camera.position.x += ( mouseX - camera.position.x ) * .05;
        //     camera.position.y += ( - mouseY - camera.position.y ) * .05;
        //     camera.lookAt( scene.position );
        //     var i = 0;
        //     for ( var ix = 0; ix < AMOUNTX; ix ++ ) {

        //         for ( var iy = 0; iy < AMOUNTY; iy ++ ) {

        //             particle = particles[ i++ ];
        //             particle.position.y = ( Math.sin( ( ix + count ) * 0.3 ) * 50 ) + ( Math.sin( ( iy + count ) * 0.5 ) * 50 );
        //             particle.scale.x = particle.scale.y = ( Math.sin( ( ix + count ) * 0.3 ) + 1 ) * 2 + ( Math.sin( ( iy + count ) * 0.5 ) + 1 ) * 2;
        //         }
        //     }
        //     renderer.render( scene, camera );
        //     count += 0.1;
        // },
        handleSelect(e)
        {   
            this.active = e
            console.log(this.active);
        },
        changeLoginType(type)
        {
            // if(type == '2')
            // {
            //     this.height = 540
            // }
            // if(type == '1')
            // {
            //     this.height = 420
            // }
                this.loginType = type
        },

        setInfoToCookie()
        {
            const self = this;
            if (self.rember) 
            {
                setCookie("username", self.username);
                setCookie("password", self.password);
                setCookie("rember", self.rember);
            }
        },

        remberClick() 
        {
            const self = this;
            if (self.rember) 
            {
                setCookie("username", self.username);
                setCookie("password", self.password);
                setCookie("rember", self.rember);
            } 
            else 
            {
                clearCookie("username");
                clearCookie("password");
                clearCookie("rember");
            }
        },
        loadRember() 
        {
            const self = this;
            self.username = getCookie("username");
            self.password = getCookie("password");
            if (self.username || self.password) 
            {
                self.rember = true;
            } 
            else 
            {
                self.rember = false;
            }
        },
        judgeSchool()
        {
            let school_id = this.$route.query.school_id;
            // 新疆大学
            if(school_id == '10755')
            {
                this.school_logo = 'https://moolsvideo.oss-cn-beijing.aliyuncs.com/baiduOCR/2023-03-31-08-55-09_6015973.png'
            }
        },
        /**
         * @name: 账号登录
         * @author: camellia
         * @date: 2020-12-25 10:07:09
         */
        accountLogin() 
        {
            try 
            {
                const self = this;
                // 请求参数
                let data = {
                    username: self.username,
                    password: self.password
                };
                // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                // 上线打包后登录需要确认
                var ENV_MODE = process.env.VUE_APP_ENV_MODE;
                if(ENV_MODE == 'production')
                {
                    API.noticeLogin(data)
                        .then(result => {
                            if(result.code > 0)
                            {
                                self.wait = true
                                // Message.success(result.msg);
                                let param = {
                                    log : result.log
                                }
                                const start = Date.now();
                                const interval = setInterval(()=>{
                                    if(period(start) > 10)
                                    {
                                        clearInterval(interval)
                                    }
                                    API.getLoginStatus(param)
                                        .then((res)=>{
                                            if(res.code > 0)
                                            {
                                                self.wait = false
                                                // 登陆成功后引导用户去往帮助页的变量
                                                // self.$store.state.help=true
                                                clearInterval(interval)
                                                if(res.type == 0)
                                                {
                                                    self.$router.push("/student/reportList");
                                                }
                                                else if(res.type == 1 || res.type == 9)
                                                {
                                                    self.$router.push("/teacher/calendar");
                                                }
                                                else if(res.type == 8)
                                                {
                                                    self.$router.push("/manager/logList");
                                                }
                                                else if(res.type == 7)
                                                {
                                                    self.$router.push("/schoolAdmin/center");
                                                }
                                                else if(res.type == 6)
                                                {
                                                    self.$router.push("/DY/index");
                                                }
                                            }
                                            else if (res.code < 0 )
                                            {
                                                clearInterval(interval)
                                            }
                                        })  

                                },2000)
                            }
                            else
                            {
                                // Message.error('系统错误');
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'warning',
                                    // 延时时间
                                    displayTime:1500
                                })
                            }
                        })          
                }
                else
                {
                    API.accountlogin(data)
                    .then(result => {
                        console.log(result);
                        if(result.code > 0)
                        {
                            // 登陆成功后引导用户去往帮助页的变量
                            self.$store.state.help=true
                            // Message.success(result.msg);
                            if(result.type == 0)
                            {// 跳学生首页
                                self.$router.push("/student/reportList");
                            }
                            else if(result.type == 1 || result.type == 9)
                            {// 跳选择身份页
                                self.$router.push("/teacher/calendar");
                            }
                            else if(result.type == 8)
                            {
                                self.$router.push("/manager/logList");
                            }
                            else if(result.type == 7)
                            {
                                self.$router.push("/schoolAdmin/center");
                            }
                            else if(result.type == 6)
                            {
                                self.$router.push("/DY/index");
                            }
                        }
                        else
                        {
                            this.$Tips({
                                // 消息提示内容
                                message:result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'warning',
                                // 延时时间
                                displayTime:1500
                            })
                        }
                    })
                    .catch(err => {});//*/
                }
            } 
            catch (error) 
            {
                // Message.error('系统错误');
                this.$Tips({
                    // 消息提示内容
                    message:result.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    // 延时时间
                    displayTime:1500
                })
            }
            // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
            // 调用公共请求接口
            // const accountLogin = data => get("/api/managerlogin/accountlogin", {param:data});
            /*const accountLogin = data => post("/index.php/backlogin/accountlogin", {data});
            accountLogin().then(result => {
                console.log(result);
                Message.success(result.data.msg);
                // self.$router.push('/manager/index');
                window.location.herf = "/#/manager/teacher/index";
            })//*/
            // self.axios
            //     .post("/index.php/backlogin/accountlogin", data)
        },
        /**
         * @name: 微信扫码登录跳页
         * @author: camellia
         * @date: 2020-12-28 10:50:05
         */
        handleClick(tab, event) {
            /*if (tab.name == "second") {
                window.location.assign(
                    "https://open.weixin.qq.com/connect/qrconnect?appid=wxc4886b0644c55d2d&redirect_uri=http://www.mools.net/lims/api/laravel/public/index.php/backlogin/wxlogin&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect"
                );
            }//*/
        }
    }
};
</script>

<style lang="scss">
@media screen and (min-width: 1366px) {
.wxQcodeBox{
    padding-left: 2.5vw ;
}
.login .qrcode{
    width: 10vw !important;
    height: 10vw !important;
    margin-top: 1.33vw !important;
}
.top{
    height: 25vw !important;
}
}
.footer{
    position: absolute;
    bottom: 2vh;
    width: 100vw;
    text-align: center;
    font: normal normal normal .6375vw Source Han Sans CN;
}
.login .inline-block{
    display: inline-block;
}
.login .textEnter{
    font: normal normal normal .85vw Source Han Sans CN;
    color: #999999;
    margin-top: 5vh;
}
.login .appTest{
    margin-left: .7vw;
    vertical-align: top;
}
.login .el-carousel__arrow{
    display: none;
}
.login .appimg{
    width:  .85vw;
    height: .85vw;
    line-height:1.4vw;
    margin: 0 auto;
}
.login .appimg>img{
    width:  .85vw;
    height: .85vw !important;
    margin: 0 auto;
}
.login .textinfo{
    font: normal normal normal .85vw Source Han Sans CN;
    margin-top: 4vh;
    color: #999999;
}
.login .textword{
    font: normal normal normal 1.7vw Source Han Sans CN;
}
.login .swiperBox{
    width: 100vw;
    height: 38vw;
    position: absolute;
    top: 6vw;
    background-color: #F4F7F8;
}
.login .swiper{
    float: left;
    width: 65vw;
    height: 38vw;
    overflow: hidden;
}
.login .swiper .el-carousel{
    width: 65vw;
    overflow: hidden;
    height:38vw;
}
.login .el-carousel__container{
    width: 65vw;
    height:38vw;
}
.login .el-carousel__indicators--horizontal {
    bottom: 10vh;
}
.login .el-carousel__button {
    display: block;
    opacity: .48;
    width: 1.5vw;
    height: .1vw;
    background-color: #0D5570;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: .3s;
}
.login .swiper .el-carousel .el-carousel-item{
    // position: absolute;
        width: 65vw;
        overflow: hidden;
    height: 80vh;
}
.login .swiper img{
    // position: absolute;
    height: 100%;
    // opacity: 1;
}

.login .swiperText{
    position: absolute;
    width: 18vw;
    top: 9vw;
    right: 4vw;
}
.login .fade-enter-active, .login .fade-leave-active {
    transition: opacity .2s;
}
.login .navmenu{
    margin-bottom: 1.275vw;
}
.login .qrcodeBox{
    text-align: center;
}
.login .qrcode{
    width: 15vw;
    height: 15vw;
    margin-top: 1.33vw;
}
.login .el-input__inner{
    height: 2.125vw;
}
.login .el-input .el-input__clear{
    font-size: 0.74vw !important;
    line-height: 2.285vw;
}
.login .el-menu--horizontal>.el-menu-item.is-active {
    font-size: 1.0625vw;
    border-bottom: .125rem solid #0D5570;
    /* color: #303133; */
    color: #0D5570;
}
.login .el-menu--horizontal>.el-menu-item {
    color: #707070;
    font-size: 1.0625vw;
    padding: 0 1vw;
    height: 3.1875vw;
    line-height:3.1875vw;
}
.login .el-menu.el-menu--horizontal{
    width: 14.45vw;
    margin: 0 auto;
    border-bottom: 0 !important;
}
.login .welcome{
    margin-top: 2.975vw;
}
.login .logo{
    width: 8.5vw;
    height: 3.06vw;
    position: absolute;
    top: 3%;
    left: 2%;
}
.login .logo>img{
    // width: 100%;
    height: 100%;
}
.login .fade-enter, .login .fade-leave-to{
  opacity: 0;
}
.el-carousel__item.is-active{
    z-index:0 !important
}
.el-checkbox__original,.el-checkbox__inner{
    z-index:0 !important
}
// #three{
//     box-sizing: border-box;
//   padding-top:6%;
//   height: 100vh;
//   overflow: hidden;
//   z-index: 999;
// }

#wx_login_container{
    // display: none;
    width: 100%;
    text-align: center
}
.login .formItem{
    text-align: center;
}
.login .loginButton{
    width: 13.6vw;
    border: 0;
    margin-top: 2.28vw;
    color: #FFFFFF;
    background-color: #0D5570;
    box-shadow: 0.053125vw 0.053125vw 0.31875vw #00000029;
    border-radius: 0.2125vw;
}
.login .el-checkbox{
    width: 13.6vw;
    text-align: left;
}
.login .telInput,.passwordInput{
    font-size: 1vw;
    width: 13.6vw;
    margin-bottom: 0.85vw;
}
.login .el-menu.el-menu--horizontal {
    border-bottom: 0 !important
}
.login .wxImg{
    position: absolute;
    width: 4.675vw;
    height: 4.675vw;
    top: 0;
    right: 0;
}
.login .pcImg,.login .wxImg{
    position: absolute;
    width: 4.675vw;
    height: 4.675vw;
    top: 0;
    right: 0;
}
.login .pcImg>img,.login .wxImg>img{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.login .top {
    position: relative;
    width: 21vw;
    overflow: hidden;
    float: left;
    margin-top: 2vw;
    height: 30vw;
    background-color: #FFFFFF;
    border-radius: 0.425vw;
    box-shadow: 0.053125vw 0.053125vw 0.31875vw #0000002e;
}
</style>